import appStrings from '@/app/utility/string.utility.js';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
// import { required } from 'vuelidate/lib/validators';
export default {
    name: 'applyReceiptUpload',
    components: {
        DatePicker
    },
    watch: {
        currentPage: function () {
            this.getApplyReceiptUploadData();
        },
        perPage: function () {
            this.currentPage = 1;
            this.getApplyReceiptUploadData();
        },
        selectAllCheckBox: function () {
            this.checkUncheckAll();
        }
    },
    data() {
        return {
            totalRows: null,
            perPage: commonHelper.perPageRecord,
            pageOptions: commonHelper.getPageOption(),
            currentPage: 1,
            loader: false,
            docNo: null,
            trxNo: null,
            fileName: null,
            fmsPrj: {
              text: null,
              value: null
            },
            status: {
                value: null,
                text: null
            },
            billCat: { value: null, text: null },
            glDateFrom: null,
            glDateTo: null,
            trxDateFrom: null,
            trxDateTo: null,
            showValueSetModal: false,
            unsubscribe: null,
            applyReceiptUploadData: [],
            applyReceiptUploadField: [
                {
                    key: 'select',
                    stickyColumn: true,
                    variant: 'primary'
                },
                {
                    key: 'status_flag',
                    label: 'Status'
                },
                {
                    key: 'status_msg',
                    label: 'Status Message'
                },
                {
                    key: 'doc_num',
                    label: 'Document No.'
                },
                {
                    key: 'trx_num',
                    label: 'Trx. No.'
                },
                {
                    key: 'applied_amt',
                    label: 'Applied Amount'
                },
                {
                    key: 'file_name'
                },
                {
                    key: 'instrument_amt',
                    label: 'Instrument Amount'
                },
                {
                    key: 'trx_amt',
                    label: 'Trx. Amount'
                },
                {
                    key: 'applied_gl_date',
                    label: 'GL Date'
                },
                {
                    key: 'trx_date',
                    label: 'Trx. Date'
                },
            ],
            showExcelUploadModal: false,
            requestId: null,
            templateId: null,
            requestNumId: null,
            requestStatus: null,
            selectAllCheckBox: false,
            payload: {}
        };
    },
    validations: {
    },
    mounted() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'shared/setActionName') {
                const actionName = state.shared.actionName;
                if (actionName === 'upload') {
                    this.showExcelUploadModal = true;
                }
                if (actionName === 'delete') {
                    this.deleteApplyReceiptUpload();
                }
                if (actionName === 'download') {
                    this.loader = true;
                    /**
                     * @param(payload, 'action name', 'file name')
                     */
                    const downloadpayload = { ...this.payload };
                    downloadpayload._limit = this.totalRows;
                    this.downloadExcel.downloadData(
                        downloadpayload,
                        'fmsTransaction/getApplyReceiptUploadData',
                        'fms-bill-upload',
                        () => (this.loader = false)
                    );
                }
            }
        });
    },
    methods: {
        checkUncheckAll() {
            this.applyReceiptUploadData = this.applyReceiptUploadData.map((data) => {
                data.select = data.status_flag !== 'PROCESSED' && this.selectAllCheckBox;
                return data;
            });
        },
        deleteApplyReceiptUpload() {
            const promt = confirm('Are you sure, you want to delete this Apply Receipt Upload?');
            if (promt) {
                const filterData = this.applyReceiptUploadData.filter(data => data.select);
                const receiptUpload = filterData.map(elem => {
                    return {
                        id: elem.loader_id
                    };
                });
                const payload = { loader_id: receiptUpload ? receiptUpload : null }
                this.loader = true;
                this.$store
                    .dispatch('fmsTransaction/deleteApplyReceiptUpload', payload)
                    .then(response => {
                        this.loader = false;
                        if (response.status === 200) {
                            this.editMode = false;
                            this.showAlert = true;
                            this.isSuccess = true;
                            alert('Record Deleted Successfully');
                            this.getApplyReceiptUploadData();
                        } else {
                            this.showAlert = true;
                            this.isSuccess = false;
                            alert(response.response.data.message)
                        }
                    })
            }
        },
        getApplyReceiptUploadData() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
            this.payload = {
                _page: this.currentPage - 1,
                _limit: this.perPage,
                status_flag: this.status.value,
                file_name: this.fileName,
                doc_num: this.docNo,
                trx_num: this.trxNo,
                gl_date_from: this.glDateFrom,
                gl_date_to: this.glDateTo,
                trx_date_from: this.trxDateFrom, 
                trx_date_to: this.trxDateTo
            };
            this.loader = true;
            this.$store
                .dispatch('fmsTransaction/getApplyReceiptUploadData', this.payload)
                .then(response => {
                    this.loader = false;
                    if (response.status === 200) {
                        this.applyReceiptUploadData = response.data.data.page;
                        this.totalRows = response.data.data.total_elements;
                    }
                })
                .catch(() => {
                    this.loader = false;
                });
            }
        },
        getTemplateDetails(item) {
            const payload = {
                _page: 0,
                _limit: 10,
                template_name: 'AR_RECEIPT_APPLY_LOADER_TMP'
            };
            this.loader = true;
            this.$store
                .dispatch('assets/getTemplateDetails', payload)
                .then(resp => {
                    this.loader = false;
                    if (resp.status === 200) {
                        this.requestId = resp.data.data.page[0].request_id;
                        this.templateId = resp.data.data.page[0].template_id;
                        this.saveSubmitRequest(item);
                    }
                })
                .catch(() => {
                    this.loader = false;
                });
        },
        saveSubmitRequest(item) {
            const payload = {
                admin_submit_req_details: [
                    {
                        request_num: 0,
                        request_id: this.requestId,
                        template_id: this.templateId,
                        schedule_type: 'asap', //pass hardcode
                        request_start_date: null,
                        request_end_date: null,
                        resubmit_interval: null,
                        resubmit_interval_unit: null,
                        day_of_month: null,
                        day_of_week: null,
                        output_format_id: 'SBLANK', //pass hardcode
                        no_of_args: 25,
                        request_parameter: null,
                        sms_flag: false,
                        email_flag: false,
                        whatsapp_flag: false,
                        child_req_count: null,
                        email_comm_template_id: null,
                        sms_comm_template_id: null,
                        whatsapp_comm_template_id: null,
                        argument1: null,
                        argument2: null,
                        argument3: null,
                        argument4: null,
                        argument5: null,
                        argument6: null,
                        argument7: null,
                        argument8: null,
                        argument9: null,
                        argument10: null,
                        argument11: null,
                        argument12: null,
                        argument13: null,
                        argument14: null,
                        argument15: null,
                        argument16: null,
                        argument17: null,
                        argument18: null,
                        argument19: null,
                        argument20: null,
                        argument21: null,
                        argument22: null,
                        argument23: null,
                        argument24: null,
                        argument25: null
                    }
                ]
            };
            this.loader = true;
            this.$store
                .dispatch('template/saveSubmitRequest', payload)
                .then(resp => {
                    this.loader = false;
                    if (resp.status === 201) {
                        this.requestNumId = resp.data.data[0].id;
                        this.getSubmitRequest(item);
                    }
                })
                .catch(() => {
                    this.loader = false;
                });
        },
        getSubmitRequest() {
            this.loader = true;
            this.$store
                .dispatch('template/getSubmitRequest', this.requestNumId)
                .then(resp => {
                    this.loader = false;
                    if (resp.status === 200) {
                        this.requestStatus = resp.data.data[0].status;
                        this.reqId = resp.data.data[0].id;
                    }
                })
                .catch(() => {
                    this.loader = false;
                });
        },
        openValueSetModal(vsetCode) {
            this.vsetCode = vsetCode;
            this.showValueSetModal = true;
            this.setTimeVsetCode = setTimeout(() => {
                const vSetData = {
                    valueSetName: vsetCode,
                    multiFlag: null
                };
                this.eventBus.$emit('valueSetCode', vSetData);
            }, 0);
        },
        selectedvalueSet(item) {
            if (this.vsetCode === appStrings.VALUESETTYPE.STATUS_FLAG) {
                this.status.text = item.value_meaning;
                this.status.value = item.value_code;
            }
        },
        closeValueSetModal() {
            this.showValueSetModal = false;
        },
        clearVsetValues(vsetCode) {
            if (vsetCode === appStrings.VALUESETTYPE.STATUS_FLAG) {
                this.status.text = null;
                this.status.value = null;
            }
        },
        clearFilters() {
            this.fileName = null;
            this.glDateFrom = null,
            this.glDateTo = null,
            this.trxDateFrom = null,
            this.trxDateTo = null,
            this.docNo = null,
            this.trxNo = null,
            this.applyReceiptUploadData = [];
            this.totalRows = null;
            this.currentPage = 1;
            this.requestStatus = null;
        }
    },
    beforeDestroy() {
        this.unsubscribe();
    }
};
